<template>
  <div class="app page-bg">
    <esports-header-authen />

    <div
      class="container register-page login-page pt-4"
      :class="[{ 'verify-code-page': isVerifyCode }]"
    >
      <b-row class="justify-content-center w-100">
        <template v-if="!isMb">
        <component
          @activeComponent="activeComponent"
          :formRegisterData="formRegisterData"
          :is="currentTabComponent"
        ></component>
        </template>
        <b-col md="8" lg="6" v-else>
            <h1 class="float-left display-4 display-xxl-2 mr-4">404</h1>
            <h4 class="pt-3 h6 font-weight-bold">{{ $t('general_inputs.txt_404') }}</h4>
            <p class="text-muted">{{ $t('general_inputs.page_not_found') }}</p>
          </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import LoginForm from "./LoginAdmin/LoginForm";

export default {
  name: "LoginPage",
  title: "login",
  components: {
    LoginForm,
  },
  data() {
    return {
      isSubmitted: false,
      formRegisterData: null,
      currentTabComponent: LoginForm,
      isVerifyCode: false,
      isMb: false
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    activeComponent(payload) {
      this.formRegisterData = payload.user;
      this.currentTabComponent = payload.name;

      if (payload.name === "RegisterEnterUserNameSocial") {
        this.isVerifyCode = true;
        return;
      }
      this.isVerifyCode = false;
    },
    onResize() {
      this.isMb = this.isMobile();
    }
  },
};
</script>